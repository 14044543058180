import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MovieService {
  style;
  display = 0;

  constructor() { }

  display0 () {
    this.display = 0;
    console.log(this.display2);
  }

display1 () {
  this.display = 1;
  console.log(this.display1);
}
display2 () {
  this.display = 2;
  console.log(this.display2);
}

}
