
import { getHostElement } from '@angular/core/src/render3';
import { timer } from 'rxjs';
import { timeout, delay } from 'q';
import { Component, OnInit, HostListener } from '@angular/core';
import { MovieService } from '../service/movie.service';

@Component({
  selector: 'app-main-component',
  templateUrl: './main-component.component.html',
  styleUrls: ['./main-component.component.scss']
})
export class MainComponentComponent implements OnInit {
  state = [];
  style;
  distance_icon;
  distance = [];
  distance_to_window = [];
  delay1;
  delay2;
  delay3;
  state_icon;
  load = false;

  constructor( public movie: MovieService) {
    for (let i = 0; i < 6; i++) {
      this.state[i] = 'hide';
    }
  }

  getStyle_1() {
            if (this.state[0] === 'show1') {
            return this.style = { 'animation-delay': '0.5s', 'animation-name': 'slide_right' };
          }
    }
    getStyle_2() {
      if (this.state[1] === 'show2') {
        return this.style = { 'animation-name': 'slide_left' };
      }
    }
    getStyle_3() {
      if (this.state[2] === 'show3') {
        return this.style = { 'animation-name': 'slide_right'};
      }
    }
    getStyle_4() {
      if (this.state[3] === 'show4') {
        return this.style = { 'animation-delay': '0.5s', 'animation-name': 'slide_left' };
      }
    }
    getStyle_5() {
      if (this.state[4] === 'show5') {
        return this.style = { 'animation-delay': '0.5s', 'animation-name': 'slide_left' };
      }
    }
    getStyle_6() {
      if (this.state[5] === 'show6') {
        return this.style = { 'animation-name': 'slide_right'};
      }
    }
    getStyle_icon() {
      if (this.state_icon === 'icon' && window.innerWidth > 630) {
        return this.style = { 'animation-name': 'slide'};
       }
       if (this.state_icon === 'icon' && window.innerWidth <= 630) {
        return this.style = { 'animation-name': 'slide', 'animation-delay': '0s'};
       }
    }

    @HostListener('window:load', ['$event'])

      onPageLoad(event: Event) {
        this.load = true;
        // console.log('loaded');
       }


  @HostListener('window:scroll', ['$event'])
    checkScroll() {
      const windowH = window.innerHeight;
      for (let i = 0; i < 6; i++) {
        this.distance[i] = document.getElementById(i + 1 + '').getBoundingClientRect().top;
      }
      for (let i = 0; i < 6; i++) {
        this.distance_to_window[i] = this.distance[i] - windowH;
      }
      const scrollPosition = window.pageYOffset;

      for (let i = 0; i < 6; i++) {
        if (this.distance_to_window[i] <= -80) {
          this.state[i]  = 'show' + (i + 1);
      }
    }

    this.distance_icon = document.getElementById('icons').getBoundingClientRect().top - windowH;
    if (this.distance_icon <= -80) {
      this.state_icon  = 'icon';
  }


    }
  ngOnInit() {

  }

}
