import { Component, HostListener } from '@angular/core';
import { MovieService } from './service/movie.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
style;
distanse;
scroll1;
scroll2;

value;
  constructor (public movie: MovieService) {
  }

  getStyle() {
    if (this.movie.display === 1 || this.movie.display === 2 ) {
      // this.value = this.scroll1;
      // window.scrollTo(0, this.value);
      // window.onscroll = function () { window.scrollTo(); };
    return this.style = { 'animation-name': 'show'};
    } else { return this.style = {'filter': 'blur(0)'} ; }
  }

  getStyleMovie() {
    // console.log(this.distanse);
    console.log(this.value);
    console.log(this.movie.display);
// window.onscroll = function () { window.scrollTo(0, 300); };

    if (this.movie.display === 0) {
      // console.log(this.value);
      // const windowH = window.innerHeight;
      // this.scroll1 = document.getElementById('1').getBoundingClientRect().top;
      // this.scroll1 = document.getElementById('1').getBoundingClientRect().top;
      // this.scroll2 = document.getElementById('1').getBoundingClientRect().bottom;
    return this.style = { };
  } else {
    this.value = Math.abs(this.scroll1);
    // console.log(this.value);
    window.scrollTo(0, this.value);
    return this.style = {} ; }
    // 'top': '7vw', 'position': 'fixed'
    //  return this.style = {'padding-top': this.value, 'padding-bottom': this.scroll1, 'position': 'absolute'} ; }
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const windowH = window.innerHeight;
    // this.distanse = document.getElementById('10').getBoundingClientRect();
    // this.scroll1 = document.getElementById('1').getBoundingClientRect().top;
    // this.scroll2 = document.getElementById('1').getBoundingClientRect().bottom;
    if (this.movie.display === 0) {

      this.scroll1 = document.getElementById('1').getBoundingClientRect().top;
      this.scroll2 = document.getElementById('1').getBoundingClientRect().bottom;
    }
    console.log(this.scroll1);
    console.log('scroll=' + this.scroll2);

  }



}
