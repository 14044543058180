import { Component, OnInit, HostListener, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
load = false;

style;

getStyle() {
  if (this.load === true) {
    // console.log('work');
    return this.style = { 'animation-name': 'slidein'};
  }
 }
  constructor(private element: ElementRef) {

   }

  @HostListener('window:load', ['$event'])

  onPageLoad(event: Event) {
    this.load = true;
    // console.log('loaded');
   }

  ngOnInit() {

  }

}
